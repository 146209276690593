<template>
	<div>
		<a-select v-model="selectedProjects" mode="multiple" style="min-width: 200px" :maxTagCount="1" class="text-primary"
			:showArrow="true" allowClear show-search>
			<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />
			<a-select-option value="all" class="text-primary">
				All
			</a-select-option>
			<a-select-option v-for="project in projectList" :key="project.id" :value="project.name" class="text-primary">
				<a-tooltip overlayClassName="change-tooltip-color" placement="left">
					<template slot="title">
						{{ project.name }}
					</template>
					{{ project.name }}
				</a-tooltip>
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
export default {
	computed: {
		instance() {
			return this.$store.state.instance
		},

		projectList() {
			return [{ id: this.instance.id, name: this.instance.name }, ...this.instance.children].sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
		},

		projectsName() {
			return this.projectList.map(p => p.name);
		},

		selectedProjects: {
			get() {
				return this.$store.state.selectedProjects
			},
			set(value) {
				if (!value) {
					value = ['all']
				} else if (value.includes('all')) {
					value = this.projectsName
				}
				this.$store.commit('SET_SELECTED_PROJECTS', value)
			}
		},
	},

	created() {
		if (!this.selectedProjects || this.selectedProjects.length === 1 && this.selectedProjects.includes('all')) {
			this.selectedProjects = this.projectsName;
		}
	}
}
</script>
